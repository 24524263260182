import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import RequestCallForm from "../common/RequestCallForm"
import RequestCallBanner from "../common/RequestCallBanner"

export default function HomeBanner() {
  return (
    <section className="top-banner top-banner--home">
      <Container>
        <Row>
          <Col lg={6} sm={12} className="d-flex flex-column">
            <RequestCallBanner />
            <RequestCallForm
              className="call-form--home"
              text="We can reach out to you"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
