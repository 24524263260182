import React from 'react'

export default function RequestCallBanner() {
    return (
        <div className="text-block">
            <h1>
                At Alcoholism.org, Recovery
                <br />Starts With One Call
            </h1>
            <h4>Our Goal is to Direct Those in Need of Alcoholism Treatment to Resources That Can Help.<br />
                Call our Hotline Number to Lean More:  <span>888-767-3708</span></h4>
        </div>
    )
}
